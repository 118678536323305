<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{name: 'job-templates-add'}"
              >
                <span class="text-nowrap">Add Job Template</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refJobTemplateListTable"
        class="position-relative"
        :items="fetchJobTemplates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Alias -->
        <template #cell(alias)="data">
          <b-media
            vertical-align="center"
          >

            <b-link
              :to="{ name: 'job-templates-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.alias }}
            </b-link>
          </b-media>
        </template>

        <!-- Number of Items -->
        <template #cell(jobItemCount)="data">
          <div class="text-nowrap">
            {{ data.item.jobItemCount }}
          </div>
        </template>

        <!-- Number of Jobs -->
        <template #cell(jobCount)="data">
          <div class="text-nowrap">
            {{ data.item.jobCount }}
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobTemplates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, BTable, BPagination, BMedia, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import useJobTemplatesList from '@/views/job-templates/useJobTemplatesList'
import jobTemplateStoreModule from '@/views/job-templates/jobTemplateStoreModule'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BMedia,
    BLink,

    vSelect,
  },
  setup() {
    const JOB_TEMPLATE_APP_STORE_MODULE_NAME = 'app-job-template'

    // Register module
    if (!store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME, jobTemplateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchJobTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalJobTemplates,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobTemplateListTable,
      refetchData,

    } = useJobTemplatesList()

    return {
      fetchJobTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalJobTemplates,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobTemplateListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
