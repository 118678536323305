import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJobTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobTemplates', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/jobTemplates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJobTemplateWithItems(ctx, jobTemplateData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/jobTemplates', jobTemplateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobTemplateWithItems(ctx, { id, jobTemplateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/jobTemplates/${id}`, jobTemplateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteJobTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/jobTemplates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
