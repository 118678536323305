import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useJobTemplatesList() {
  // Use toast
  const toast = useToast()

  const refJobTemplateListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'alias', label: 'Alias', sortable: true },
    { key: 'jobItemCount', label: 'Items' },
    { key: 'jobCount', label: 'Jobs' },
  ]

  const perPage = ref(10)
  const totalJobTemplates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('alias')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refJobTemplateListTable.value ? refJobTemplateListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJobTemplates.value,
    }
  })

  const refetchData = () => {
    refJobTemplateListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchJobTemplates = (ctx, callback) => {
    store
      .dispatch('app-job-template/fetchJobTemplates', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `jt.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { jobTemplates, meta } = response.data

        callback(jobTemplates)
        totalJobTemplates.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching job templates list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchJobTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalJobTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refJobTemplateListTable,
  }
}
